import {
  GET_ARTICLES_GQL,
  GET_ARTICLE_GQL,
  GET_CUSTOMIZED_FORM_LIST_GQL,
  GET_MEDIAS_GQL,
  GET_PAGE_CONTENT_GQL,
  GET_SETTING_VALUES_BY_BELONG_ID_GQL,
  GET_SITE_MODULES_GQL,
  GET_TOP_ARTICLES_GQL
} from '@/services/gql/global';
import { useApollo } from './apollo';
import { message } from 'antd';

function onError(error: any) {
  try {
    error = JSON.stringify(error);
  } catch (e) {
    error = error.toString();
  }

  message.error('ERROR:' + error);
}

export namespace service {
  const SITE_ID = import.meta.env.VITE_APP_GRAPHQL_SITE_ID;
  const client = useApollo();

  export const getSiteModules = async (siteId: string = SITE_ID): Promise<Graphql.Node[] | undefined> => {
    try {
      const { data, errors } = await client.query({
        fetchPolicy: 'no-cache',
        query: GET_SITE_MODULES_GQL,
        variables: { siteId }
      });

      if (errors) {
        onError(errors);
      }

      return data?.nodes;
    } catch (error) {
      onError(error);
    }
  };

  export const getSettingValuesByBelongId = async (belongId: string): Promise<Graphql.SettingValues | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_SETTING_VALUES_BY_BELONG_ID_GQL,
        variables: { belongId }
      });

      if (errors) {
        onError(errors);
      }

      return data?.settingValues;
    } catch (error) {
      onError(error);
    }
  };

  export const getArticles = async (
    nodeId: string,
    page?: number,
    pageSize = 999,
    siteId: string = SITE_ID
  ): Promise<{ articleCount: number; articles: Graphql.Article[] | undefined }> => {
    try {
      const { data, errors } = await client.query({
        query: GET_ARTICLES_GQL,
        variables: { siteId, nodeId, page, pageSize }
      });

      if (errors) {
        onError(errors);
      }

      return data;
    } catch (error) {
      onError(error);
    }

    return {
      articleCount: 0,
      articles: undefined
    };
  };

  export const getTopArticles = async (nodeId: string): Promise<Graphql.Article[] | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_TOP_ARTICLES_GQL,
        variables: { nodeId }
      });

      if (errors) {
        onError(errors);
      }

      return data?.topArticles;
    } catch (error) {
      onError(error);
    }
  };

  export const getArticle = async (articleId: string): Promise<Graphql.Article | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_ARTICLE_GQL,
        variables: { id: articleId }
      });

      if (errors) {
        onError(errors);
      }

      return data?.article;
    } catch (error) {
      onError(error);
    }
  };

  export const getPageContent = async (nodeId: string): Promise<Graphql.Article | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_PAGE_CONTENT_GQL,
        variables: { nodeId }
      });

      if (errors) {
        onError(errors);
      }

      return data?.page;
    } catch (error) {
      onError(error);
    }
  };

  export const getMedias = async (nodeId: string, page?: number, pageSize = 999): Promise<Graphql.Media[] | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_MEDIAS_GQL,
        variables: { nodeId, page, pageSize }
      });

      if (errors) {
        onError(errors);
      }

      return data?.mediaList;
    } catch (error) {
      onError(error);
    }
  };

  export const getCustomizedTableData = async (
    nodeId: string,
    page?: number,
    pageSize = 999
  ): Promise<Graphql.CustomizedForm[] | undefined> => {
    try {
      const { data, errors } = await client.query({
        query: GET_CUSTOMIZED_FORM_LIST_GQL,
        variables: { nodeId, page, pageSize }
      });

      if (errors) {
        onError(errors);
      }

      return data?.customizedForms;
    } catch (error) {
      onError(error);
    }
  };
}
