import { ApolloClient, HttpLink, InMemoryCache, from, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
// import { createUploadLink } from 'apollo-upload-client';
import { AUTH_KEY } from '@/config/config';
function createApolloClient() {
  const token = localStorage.getItem(AUTH_KEY) || '';
  const { VITE_APP_GRAPHQL_WSS_URL, VITE_APP_GRAPHQL_HTTPS_URL } = import.meta.env;

  const httpLink = new HttpLink({
    uri: VITE_APP_GRAPHQL_HTTPS_URL,
    headers: {
      authorization: `bearer ${token}`
    }
  });

  // const uploadLink = createUploadLink({
  //   uri: VITE_APP_GRAPHQL_HTTPS_URL, // Apollo Server is served from port 4000
  //   headers: {
  //     'keep-alive': 'true',
  //     authorization: `bearer ${token}`
  //   }
  // });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: VITE_APP_GRAPHQL_WSS_URL,
      lazy: true,
      keepAlive: 10000,
      connectionParams: async () => {
        return {
          headers: {
            authorization: `bearer ${token}`
          }
        };
      }
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink
    // uploadLink
  );

  return new ApolloClient({
    link: from([splitLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache'
      },
      query: {
        fetchPolicy: 'no-cache'
      }
    },
    onError: (error) => {
      // Handle the error globally
      console.error('Global error:', error);
    }
  });
}

const apolloClient = createApolloClient();
export const useApollo = () => apolloClient;
