import React from 'react';
import { RouteObject } from '../interFace';
const Layout = React.lazy(() => import('@/views/szyd/Layout'));
const Home = React.lazy(() => import('@/views/szyd/Home'));
const Xxgk = React.lazy(() => import('@/views/szyd/xxgk'));
const Gnbj = React.lazy(() => import('@/views/szyd/gnbj'));
const Aqjn = React.lazy(() => import('@/views/szyd/aqjn'));

export default [
  {
    path: '/szyd',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'xxgk',
        element: <Xxgk />
      },
      {
        path: 'gnbj',
        element: <Gnbj />
      },
      {
        path: 'aqjn',
        element: <Aqjn />
      }
    ]
  }
] as RouteObject[];
