import home from '@/assets/images/icon_home.png';
import close from '@/assets/images/icon_close.png';
import IconButton from './IconButton';
import { useLocation } from 'react-router-dom';
import { useGoTo } from '@/routers/hooks';

export default ({ className, items: [_home, ..._items] }: { className?: string; items: { name: string; href: string }[] }) => {
  const { pathname } = useLocation();
  const goTo = useGoTo();

  return (
    <div className={'fixed bottom-5 right-9 ' + className}>
      {pathname !== _home.href && (
        <>
          <IconButton icon={<img src={home} />} text={_home.name} onClick={() => goTo(_home.href)} />
          <div className='py-3 my-5 rounded-lg bg-white flex flex-col items-center gap-3'>
            {_items.map((item) => (
              <div
                className='p-3 rounded-lg cursor-pointer data-[active=true]:bg-[#97F8E9] leading-tight text-[14px] w-[28px]'
                data-active={pathname === item.href}
                key={item.href}
                onClick={() => goTo(item.href)}>
                {item.name}
              </div>
            ))}
          </div>
        </>
      )}
      <IconButton icon={<img src={close} />} text='关闭' onClick={() => window.close()} />
    </div>
  );
};
