import { RouteObject } from '@/routers/interFace';
import { useLocation, useParams } from 'react-router-dom';
import type { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';

export const searchRoute = (routes: RouteObject[] = [], path = usePath()): RouteObject => {
  // console.log({ path });
  let result: RouteObject = {};
  for (const item of routes) {
    if (item.path === path) return item;
    if (item.children) {
      const res = searchRoute(item.children);
      if (Object.keys(res).length) result = res;
    }
  }
  return result;
};

export const usePath: any = () => {
  const { pathname } = useLocation();
  const params = useParams();
  return Object.entries(params).reduce((path: any, [key, value]) => {
    return path.replace(`/${value}`, `/:${key}`);
  }, pathname);
};

/**
 * @description 对象数组深克隆
 * @param {Object} obj 源对象
 * @return object
 */
export const deepCopy = <T>(obj: any): T => {
  let newObj: any;
  try {
    newObj = obj.push ? [] : {};
  } catch (error) {
    newObj = {};
  }
  for (const attr in obj) {
    if (typeof obj[attr] === 'object') {
      newObj[attr] = deepCopy(obj[attr]);
    } else {
      newObj[attr] = obj[attr];
    }
  }
  return newObj;
};

//email
export const validateEmail = (text: string) => {
  if (text == '') return true;
  const emailReg =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  if (text && emailReg.test(text)) {
    return true;
  }
  return false;
};
//phone
export const validatePhone = (text: string, type: string) => {
  console.log('validatePhone' + text);
  if (text == '') return true;
  // const regPhone = /^[0-9]{5,17}$/; //带区号校验
  // (+65)SG验证-^[89]\d{7}$
  // (+60)MY验证-^（1）[0-46-9]-*[0-9]{7,8}$
  // (+63)PH验证-（[0]\d{3}\d{3}\d{4}）
  let regPhone = /^[89]\d{7}$/;
  if (type == 'SG' || type == '+65') {
    regPhone = /^[89]\d{7}$/;
  }
  if (type == 'MY' || type == '+60') {
    regPhone = /^(1)[0-46-9]-*[0-9]{7,8}$/;
  }
  if (type == 'PH' || type == '+63') {
    regPhone = /^\d{10}$/;
  }

  if (text && regPhone.test(text)) {
    return true;
  }

  return false;
};
export const validatePassword = (text: string) => {
  if (text == '') return true;
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&[\]<>()'"_\-.,;:/}{|~+=#^`])[A-Za-z\d@$!%*?&[\]<>()'"_\-.,:;/|}{~+=#^`]{8,128}$/;
  if (text && reg.test(text)) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const debounce = (fn: Function, delay = 500) => {
  let timer;
  return function (this: unknown) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
};

export const throttle = (fn: any, delay = 500) => {
  let valid = true;
  return function (this: unknown) {
    if (valid) {
      //如果阀门已经打开，就继续往下
      setTimeout(() => {
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments); //定时器结束后执行
        valid = true; //执行完成后打开阀门
      }, delay);
      valid = false; //关闭阀门
    }
  };
};

export const validateUserName = (text: string) => {
  if (text == '' || text == undefined) return true;
  //email
  const name = /^[a-zA-Z., \.]{0,64}$/;
  if (text && name.test(text)) {
    return true;
  }

  return false;
};

export const parseArray2Tree = (array: Array<any> = [], config: { labelIndex?: string; valueIndex?: string; parentIndex?: string }) => {
  const tempMap = {};
  const valueKey = config.valueIndex ?? 'value';
  const labelKey = config.labelIndex ?? 'label';
  const parentKey = config.parentIndex ?? 'parentId';

  array.forEach((item) => {
    item.value = item[valueKey];
    item.label = item[labelKey];
    item.parent = item[parentKey];
    tempMap[item[valueKey]] = item;
  });

  Object.keys(tempMap).forEach((key) => {
    const item = tempMap[key];
    if (item.parent) {
      if (!tempMap[item.parent]?.['children']) tempMap[item.parent]['children'] = [];
      tempMap[item.parent]['children'].push(item);
    }
  });

  Object.keys(tempMap).forEach((key) => {
    const item = tempMap[key];
    if (item.parent) {
      delete tempMap[key];
    }
  });
  return Object.values(tempMap);
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const parseArray2TreeAndMap = (array: Array<any>, config: { id?: string; parentId?: string; children?: string } = {}) => {
  const { id = 'id', parentId = 'parentId', children = 'children' } = config;
  const nodeMap = {};
  const nodeTrees = array
    .map((item) => {
      nodeMap[item[id]] = item;

      return item;
    })
    .map((item) => {
      if (item[parentId]) {
        if (!nodeMap[item[parentId]][children]) nodeMap[item[parentId]][children] = [];

        nodeMap[item[parentId]][children].push(item);
      }

      return item;
    })
    .filter((item) => !item[parentId]);

  return { nodeMap, nodeTrees };
};

export const getMediasUrl = (url?: string, defaultUrl?: string) => {
  if (!url) return;

  if (url.startsWith('http')) return encodeURI(url);

  return encodeURI(`${import.meta.env.VITE_APP_FILE_UPLOAD_URL}/${url}`);
};

export const html2Text = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.innerText;
};

export const formatDate = (date?: string, isTime?: boolean) => {
  const _date = dayjs(date);

  _date.add(8, 'h');

  if (isTime) return _date.format('YYYY-MM-DD HH:mm');

  return _date.format('YYYY-MM-DD');
};

export const loadImg = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = getMediasUrl(url) as string;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      resolve(null);
    };
  });
};
