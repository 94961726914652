import React from 'react';
import { RouteObject } from '../interFace';
const Layout = React.lazy(() => import('@/views/dlxc/Layout'));
const Home = React.lazy(() => import('@/views/dlxc/Home'));
const Xcwm = React.lazy(() => import('@/views/dlxc/xcwm'));
const Xczx = React.lazy(() => import('@/views/dlxc/xczx'));
const Tscy = React.lazy(() => import('@/views/dlxc/tscy'));
const Dlfn = React.lazy(() => import('@/views/dlxc/dlfn'));
const Zxyw = React.lazy(() => import('@/views/dlxc/zxyw'));
const Nyjs = React.lazy(() => import('@/views/dlxc/nyjs'));

export default [
  {
    path: '/dlxc',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'zxyw',
        element: <Zxyw />
      },
      {
        path: 'xcwm',
        element: <Xcwm />
      },
      {
        path: 'xczx',
        element: <Xczx />
      },
      {
        path: 'tscy',
        element: <Tscy />
      },
      {
        path: 'nyjs',
        element: <Nyjs />
      },
      {
        path: 'dlfn',
        element: <Dlfn />
      }
    ]
  }
] as RouteObject[];
