import { useNavigate } from 'react-router-dom';

export function useGoTo() {
  const navigate = useNavigate();

  function goTo(path: string, menus?: any) {
    if (path.startsWith('http')) {
      return navigate('/iframeBox', { state: { path, menus } });
    }

    navigate(path);
  }

  return goTo;
}
