import { Store, combineReducers, legacy_createStore as createStore } from 'redux';
import global from '@/redux/modules/global/reducer';

// 创建reducer(拆分reducer)
const reducer = combineReducers({ global });

// @ts-ignore
const store: Store = createStore(reducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export { store };
