import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '@/redux';
import App from '@/App';
import '@/assets/css/index.css';
import '@/assets/css/quill.snow.css';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';

dayjs.locale('zh-cn');

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <App />
  </Provider>
);
