import { AnyAction } from 'redux';
import { GlobalState } from '@/redux/interface';
import produce from 'immer';
import * as types from '@/redux/mutation-types';

export const globalState: GlobalState = {
  token: '',
  email: '',
  language: '',
  isLoading: 0,
  nodeTrees: [],
  nodeMap: {}
};

// global reducer
const global = (state: GlobalState = globalState, action: AnyAction) =>
  produce(state, (draftState) => {
    switch (action.type) {
      case types.SET_IS_LOADING:
        draftState.isLoading = action.isLoading ? draftState.isLoading + 1 : draftState.isLoading - 1;
        break;

      case types.SET_NODE_DATA:
        draftState.nodeTrees = action.nodeTrees;
        draftState.nodeMap = action.nodeMap;
        break;

      default:
        return draftState;
    }
  });

export default global;
