import { NodeTree } from '@/redux/interface';
import * as types from '@/redux/mutation-types';

export const setIsLoading = (isLoading: boolean) => ({
  type: types.SET_IS_LOADING,
  isLoading
});

export const setNodeData = (node: { nodeTrees: NodeTree[]; nodeMap: Record<string, NodeTree> }) => ({
  type: types.SET_NODE_DATA,
  ...node
});
