import React from 'react';
import { RouteObject } from '../interFace';
const Layout = React.lazy(() => import('@/views/szfw/Layout'));
const Home = React.lazy(() => import('@/views/szfw/Home'));
const Jjtd = React.lazy(() => import('@/views/szfw/jjtd'));
const Wggl = React.lazy(() => import('@/views/szfw/wggl'));
const Yxfw = React.lazy(() => import('@/views/szfw/yxfw'));

export default [
  {
    path: '/szfw',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'jjtd',
        element: <Jjtd />
      },
      {
        path: 'wggl',
        element: <Wggl />
      },
      {
        path: 'yxfw',
        element: <Yxfw />
      }
    ]
  }
] as RouteObject[];
