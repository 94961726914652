import React from 'react';
import { RouteObject } from '../interFace';
const Layout = React.lazy(() => import('@/views/szzd/Layout'));
const Home = React.lazy(() => import('@/views/szzd/Home'));
const Khqy = React.lazy(() => import('@/views/szzd/khqy'));
const Ywlc = React.lazy(() => import('@/views/szzd/ywlc'));

export default [
  {
    path: '/szzd',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'khqy',
        element: <Khqy />
      },
      {
        path: 'ywlc',
        element: <Ywlc />
      }
    ]
  }
] as RouteObject[];
