import { useLocation, useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import bgImg from '@/assets/images/bg_horizontal.jpg';
import Nav from '@/components/Nav';

export default () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <>
      <div
        className='w-full h-full overflow-hidden bg-cover box-border'
        style={{ backgroundImage: `url('${bgImg}')`, paddingRight: state?.menus ? 144 : 0 }}>
        <iframe width='100%' height='100%' frameBorder='0' src={state?.path} />;
      </div>
      {state?.menus ? (
        <Nav items={state.menus} />
      ) : (
        <LeftOutlined className='fixed top-2 left-2 p-2 bg-white' onClick={() => navigate(-1)} />
      )}
    </>
  );
};
