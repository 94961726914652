import React from 'react';
import { RouteObject } from '../interFace';
const Layout = React.lazy(() => import('@/views/styl/Layout'));
const Home = React.lazy(() => import('@/views/styl/Home'));
const Zhny = React.lazy(() => import('@/views/styl/zhny'));
const Dntd = React.lazy(() => import('@/views/styl/dntd'));
const Cyzx = React.lazy(() => import('@/views/styl/cyzx'));
const Jyyn = React.lazy(() => import('@/views/styl/jyyn'));
const Tdftzh = React.lazy(() => import('@/views/styl/tdftzh'));

export default [
  {
    path: '/styl',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'tdftzh',
        element: <Tdftzh />
      },
      {
        path: 'zhny',
        element: <Zhny />
      },
      {
        path: 'dntd',
        element: <Dntd />
      },
      {
        path: 'cyzx',
        element: <Cyzx />
      },
      {
        path: 'jyyn',
        element: <Jyyn />
      }
    ]
  }
] as RouteObject[];
