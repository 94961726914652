import { Suspense, useEffect, useState } from 'react';
import { HashRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/services/apollo';
import { service } from '@/services/service';
import { parseArray2TreeAndMap } from '@/utils/utils';
import { setNodeData } from '@/redux/modules/global/action';
import Router from '@/routers';
import Spin from '@/components/Spin';
import zhCN from 'antd/locale/zh_CN';

const App = () => {
  const apolloClient = useApollo();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  async function getModules() {
    setIsLoading(true);

    const nodes = await service.getSiteModules();

    if (!nodes) {
      return console.error('获取模块失败');
    }

    dispatch(setNodeData(parseArray2TreeAndMap(nodes)));
    setIsLoading(false);
  }

  useEffect(() => {
    getModules();
  }, []);

  return (
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#22B19B',
          colorLink: '#009D85',
          colorBorder: '#C3C3C3',
          colorTextBase: '#333333',
          fontSize: 18,
          borderRadius: 8,
          controlHeight: 48
        }
      }}>
      <Spin fullscreen spinning={isLoading} />
      <ApolloProvider client={apolloClient}>
        <HashRouter>
          <Suspense>
            <Router />
          </Suspense>
        </HashRouter>
      </ApolloProvider>
    </ConfigProvider>
  );
};
const mapStateToProps = (state: any) => state.global;
export default connect(mapStateToProps)(App);
